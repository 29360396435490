<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="标题:">
                    <el-input v-model="queryParams.name" placeholder="请输入标题" clearable></el-input>
                </el-form-item>

                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border
                ref="multipleTable">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="class_id" label="分类id" width="80" align="center"></el-table-column>
                <el-table-column prop="name" label="标题" align="center"></el-table-column>
                <el-table-column prop="content" label="内容" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize"
                v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="1200px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="分类id " prop="class_id">
                    <el-input v-model="form.class_id" placeholder="请输入分类id"></el-input>
                </el-form-item>
                <el-form-item label="标题 " prop="name">
                    <el-input v-model="form.name" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="内容 " prop="content">
                    <quill-editor class="editor" ref="myTextEditor" v-model="form.content" :options="editorOption">
                    </quill-editor>
                </el-form-item>
                <el-form-item label="状态" prop="state" style="margin-top:110px;">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import { quillEditor } from 'vue-quill-editor';
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import loadEvents from '@/utils/loading'
    import Url from '@/components/Url';
    import { request } from '@/common/request';
    import { deal } from '@/common/main';
    let loads = new loadEvents();
    export default {
        name: 'seller',
        components: {
            Breadcrumb,
            Pagination,
            Url,
            quillEditor
        },
        data() {
            return {
                defaultImg: require('../../assets/img/zhan.jpeg'),
                multiple: false,
                urlList: {},
                urlVisible: false,
                header: {},
                menuList: ['商品', '商品模板'],
                queryParams: {
                    ty: 1,
                    ctime: '',
                    etime: '',
                    searchName: '',
                    page: 1,
                    pagesize: 10
                },
                editorOption: {
                    placeholder: '请输入商品描述'
                },
                //验证规则
                rules: {
                    class_id: [{
                        required: true,
                        message: '分类id不能为空',
                        trigger: 'blur'
                    },
                    {
                        pattern: '^[0-9]*$',
                        message: "请输入数字"
                    }],
                    name: [{
                        required: true,
                        message: '标题不能为空',
                        trigger: 'blur'
                    }],
                    content: [{
                        required: true,
                        message: '内容不能为空',
                        trigger: 'blur'
                    }],
                    state: [{
                        required: true,
                        message: '状态不能为空',
                        trigger: 'blur'
                    }],

                },
                loading: false,
                pageInfo: {},
                addVisible: false,
                confirmVisible: false,
                confirmContent: '',
                title: '加载中',
                mode: 'add',
                form: {
                    state: 2
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                }
            }
        },
        created() {
            this.header.token = localStorage.getItem('token');
            this.header.platform = 'admin';
            deal.getconf('ad', this)
            this.loadData();
        },
        methods: {
            //商品模板列表
            loadData() {
                this.loading = true;
                let _this = this
                request.get('/goods/template/goods/list', this.queryParams).then(ret => {
                    if (ret.code == 1) {
                        this.loading = false;
                        this.pageInfo = ret.data;
                        this.pageInfo.list = ret.data.list;
                        this.pageInfo.list.map(function (val) {
                            // val.ctime = deal.timestamp(val.ctime, 'unix');
                            // val.etime = deal.timestamp(val.etime, 'unix');
                            if (_this.platformList) {
                                _this.platformList.map((item) => {
                                    if (item.key === val.platform) {
                                        _this.$set(val, 'platform_name', item.name)
                                    }
                                })
                            }
                            return val;
                        })
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            },
            //分页
            changeQuery() {
                this.queryParams.page = 1
                this.loadData()
            },
            refreshPageSize(val) {
                this.queryParams.pagesize = val;
                this.loadData();
            },
            refreshPageNum(val) {
                this.queryParams.page = val;
                this.loadData();
            },
            //新增
            add() {
                this.mode = 'add';
                this.title = '新增商品模板';
                this.form = {
                    state: 2,
                    url: ''
                };
                this.multiple = true
                this.addVisible = true;
                if (this.$refs.form) {
                    this.$refs.form.clearValidate()
                }
            },
            //编辑
            edit(index, row) {
                this.mode = 'edit';
                this.title = '编辑商品模板';
                this.multiple = false
                this.current = row.id;
                const item = this.pageInfo.list[index];
                let time = [item.ctime, item.etime]
                //处理时间
                this.form = Object.assign({}, item);
                this.$set(this.form, 'time', time);
                this.form.class_id = this.form.class_id + ''
                this.addVisible = true;
            },
            //状态
            state(row, state) {
                if (state == 2) {
                    this.confirmContent = '确定是否上线?';
                } else {
                    this.confirmContent = '确定是否下线?';
                }
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.ctime = deal.timestamp(this.form.ctime);
                this.form.etime = deal.timestamp(this.form.etime);
                this.confirmVisible = true;
            },
            //增加编辑
            saveData() {
                if (this.$refs.form) {
                    this.$refs.form.validate(valid => {
                        console.log('ddddd', this.form)
                        if (valid) {
                            console.log('wwwww', this.form)
                            var url = this.mode == 'add' ? '/goods/template/goods/add' : '/goods/template/goods/edit';
                            console.log(5555, this.form)
                            request.post(url, this.form).then(ret => {
                                if (ret.code == 1) {
                                    this.addVisible = false;
                                    this.loadData();
                                    this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                        '成功');
                                    this.$refs.form.clearValidate()
                                } else {
                                    this.$message.error(ret.msg);
                                }
                            });
                        } else {
                            this.$message.error('请补全信息');
                            return false;
                        }
                    });
                };
            },
            // 确定下线
            stateData() {
                request.post('/goods/template/goods/edit', this.form).then(ret => {
                    if (ret.code == 1) {
                        this.$message.success('操作成功');
                        this.loadData();
                        this.confirmVisible = false;
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            }
        }
    };
</script>

<style scoped>
    .red {
        color: #ff0000;
    }

    .green {
        color: green;
    }

    .span_gray {
        font-size: 12px;
        color: gray;
    }

    .dialog_box>>>.el-dialog__body {
        padding: 20px 30px 20px 60px;
    }

    .dialog_box>>>.el-dialog__header {
        padding: 30px 30px 10px;
    }

    .dialog_box .el-form-item--small.el-form-item {
        margin-bottom: 20px;
    }

    .dialog_box .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .dialog_box .avatar {
        font-size: 28px;
        width: 150px;
        height: 200px;
        line-height: 200px;
    }

    .dialog_box .up {
        margin-top: 10px;
        margin-bottom: -10px;
    }

    .dialog_box .el-button {
        margin-left: 20px;
    }

    /* 文本编辑样式 */
    .editor {

        height: 300px;
    }

    .editor>>>.ql-container {
        min-height: 300px;
    }
</style>